import React, { useCallback } from 'react'
import { motion, useAnimation } from 'framer-motion';
import Image from 'gatsby-plugin-sanity-image';
import Typed from "react-typed"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ReactPlayer from 'react-player';
import { wrapper } from '../../media/backgroundVideo.module.css';
import { Scroll } from '../../ui';

// Animation Variants
const headingVars = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: 5.5,
    },
  },
};

const scrollerVars = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      delay: 2,
    },
  },
};



const HeroBanner = ({ heading, rotatingWords , video, image}) => {
  const playerRef = React.createRef();

  const headingControls = useAnimation();

  headingControls.start({
    opacity: 1,
    y: 20,
    transition: {
      duration: 1,
      delay: 5.5,
    },
  });

  //  Hide and show text when the video ends
  const sequence = async () => {
    await headingControls.start({
      opacity: 0,
      y: 0,
      transition: {
        duration: 0.3,
      },
    });
    return await headingControls.start({
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 5.5,
      },
    });
  };

  // When video extends, replay froms start and run text sequence
  const onVideoEnd = useCallback(() => {
    playerRef.current.seekTo(0.1);
    sequence();
  }, [playerRef]);

  return (
    <>
      <div className="grid grid-cols-14 relative bg-charcoal">
        {video && (
          <div className="block row-start-1 col-start-1 col-end-15 z-0 w-full relative">
            <div className="w-full 2xl:p-0 h-screen relative">
              <div className={`${wrapper} object-cover`}>
                <ReactPlayer
                  ref={playerRef}
                  url={video}
                  playing={true}
                  muted
                  playsinline={true}
                  onEnded={() => onVideoEnd()}
                />
              </div>
            </div>
          </div>
        )}
        <>
          {image && (
            <>
              <Image
                className={`row-start-1 col-start-1 col-end-15 z-0 w-full h-screen object-cover ${
                  video && 'hidden'
                }`}
                {...image}
              />
              <div
                class={`mask block z-20 absolute inset-0 bg-black bg-opacity-30 ${
                  video && 'hidden'
                }`}
              ></div>
            </>
          )}
          {heading && rotatingWords && (
            <motion.h1
              variants={headingVars}
              initial="hidden"
              animate={headingControls}
              exit="hidden"
              className="row-start-1 col-start-2 col-end-14 text-3xl lg:text-6xl 2xl:text-7xl flex items-center justify-center z-30"
            >
              <span className="mr-2 lg:mr-4 border-b-4 border-transparent text-white">
                {heading}
              </span>
              <span className="relative font-display border-b lg:border-b-4 border-brand text-white">
                <Typed strings={rotatingWords} typeSpeed={300} backDelay={6000} loop />
              </span>
            </motion.h1>
          )}
        </>
        <AnchorLink
          href="#page-banner-anchor"
          className="text-white absolute z-30 bottom-4 left-1/2 transform -translate-x-2/4"
        >
          <motion.div
            variants={scrollerVars}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-white text-center"
          >
            <Scroll />
            <div className="hidden text-sm lg:text-base uppercase leading-none font-display tracking-wide">
              Scroll down
            </div>
          </motion.div>
        </AnchorLink>
      </div>
      <div id="page-banner-anchor"></div>
    </>
  );
};

export default HeroBanner;
