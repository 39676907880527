import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

const aniVars = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.99,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      delay: 0.3,
      duration: 0.5,
    },
  },
};

const PageBanner = ({
  caption,
  heading,
  scrollText,
  image,
  logo,
  reversedLogo,
}) => {
  return (
    <>
      <div className='lg:grid lg:grid-cols-14 lg:grid-rows-1 relative'>
        <div className='z-0 lg:row-start-1 lg:col-start-1 lg:col-end-15 relative aspect-w-9 aspect-h-6 lg:aspect-h-5 xl:aspect-h-4 2xl:aspect-h-3 '>
          <Image
            className='absolute inset-0 object-cover filter brightness-50 '
            {...image}
          />
        </div>
        <div className='bg-tint lg:bg-transparent px-gutter py-8 lg:pb-20 lg:text-white z-20 lg:row-start-1 lg:col-start-1 lg:col-end-8 lg:flex lg:flex-col lg:justify-end'>
          <motion.div initial='hidden' animate='visible' variants={aniVars}>
            <h1 className='text-3xl md:text-4xl lg:text-5xl font-display text-brand lg:text-white'>
              {heading && heading}
            </h1>
            <p className='my-4 lg:mb-6 lg:mt-2 lg:text-lg xl:text-xl leading-normal'>
              {caption && caption}
            </p>
            {scrollText && (
              <AnchorLink
                href='#page-banner-anchor'
                className='self-start btn-branded lg:text-white'
              >
                {scrollText}
              </AnchorLink>
            )}
          </motion.div>
          <div className='mt-10 lg:mt-0 lg:absolute bottom-5 right-5 z-[8000]'>
            {logo && (
              <Image
                className='hidden lg:block w-60 object-contain'
                {...logo}
              />
            )}
            {reversedLogo && (
              <Image
                className='lg:hidden w-40 object-contain'
                {...reversedLogo}
              />
            )}
          </div>
        </div>
      </div>
      <div id='page-banner-anchor'></div>
    </>
  );
};

export default PageBanner;
