import React from 'react'

import { BlockWrapper } from '../blockWrapper';

import { Card } from '../../ui';

const CardSet = ({ layout, bgTint, blockStyle, cardWidth, heading, items }) => {

  let colWrap = null;
  if (cardWidth = 2) {
    colWrap = 'md:grid-cols-2'
  } else {
    colWrap = 'md:grid-cols-3';
  }
    return (
      <div id="cards">
        {layout === 'grid' ? (
          <BlockWrapper className={`py-12 md:py-32 px-gutter ${bgTint && 'bg-tint'}`}>
            {heading && <h2 className="text-2xl lg:text-4xl">{heading}</h2>}
            <div className={`grid grid-cols-1 gap-y-12 gap-x-8 xl:gap-x-12 ${colWrap}`}>
              {items.map((item) => {
                return <Card {...item} />;
              })}
            </div>
          </BlockWrapper>
        ) : (
          <BlockWrapper className={`py-12 md:py-32 px-gutter ${bgTint && 'bg-tint'}`}>
            {heading && <h2 className="text-2xl lg:text-4xl">{heading}</h2>}
            <div className={`grid grid-cols-1 gap-y-12 gap-x-8 xl:gap-x-12 ${colWrap}`}>
              {items.map((item) => {
                return <Card {...item} />;
              })}
            </div>
          </BlockWrapper>
        )}
      </div>
    );
}

export default CardSet
